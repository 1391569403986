// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

// Import assets
import './footer.scss'
import {
    project_logo,
    Facebook,
    Insta,
    YouTube,
    AppleStore,
    GooglePlay,
    CollapseLangIcon,
    google_play, apple_store, facebook_Icon
} from "../../assets/images";
import {ReactComponent as CompanyIcon} from '../../assets/images/4steps.svg';
import {ReactComponent as Facebook_Icon} from '../../assets/images/icons/Vector (1).svg';

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {ChangeLanguage, GetContacts} from "../../redux/actions";
import {Collapse, Dropdown, Menu} from 'antd';
import {languages} from "../../constants/staticData";
import {generateFileMediaUrl, generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {history} from "../../configs/history";

const {Panel} = Collapse;

function Footer(props) {
    const {contacts, staticTexts, selectedLanguage} = props;

    useEffect(() => {
        props.GetContacts();
    }, []);

    const menuLanguage = (
        <Menu className="languages-dropdown">
            {
                languages?.length && languages?.map((item) => {
                    return selectedLanguage?.code !== item.code &&
                        <Menu.Item key={item.id}
                                   onClick={() => {
                                       props.ChangeLanguage(item)
                                   }}
                                   className={'language-dropdown-item'}>
                            <img src={item?.icon} alt=""/>
                            <div>{item.code}</div>
                        </Menu.Item>
                })
            }
        </Menu>
    );

    return <footer className='footer-wrapper'>
        <div className="top-side">
            <div className="col">
                <div className="logo-wrapper">
                    <img src={project_logo} alt=""/>
                </div>
                <div className="footer-description">{staticTexts?.footer_description}</div>
            </div>
            <div className="col">
                <div className="col-title">{staticTexts?.footer_menu_title}</div>
                <a className="nav-item" href={'/#main'}>{props?.staticTexts?.menu_item_main}</a>
                <a className="nav-item" href={'/#services'}>{props?.staticTexts?.menu_item_services}</a>
                <a className="nav-item" href={'/#about'}>{props?.staticTexts?.menu_item_about}</a>
                <a className="nav-item" href={'/#app'}>{props?.staticTexts?.menu_item_app}</a>
                <div className={'nav-item'}>
                    <a href={`${selectedLanguage?.code.includes('hy') ?
                        'https://app.fortunecup.am/resources/files/Fortune-text--HY.pdf' :
                        'https://app.fortunecup.am/resources/files/Fortune-text---ENG.pdf'} `}
                       target={'_blank'}>
                        {staticTexts?.footer_terms_and_conditions}
                    </a>
                </div>
            </div>
            <div className="col">
                <div className="col-title">{staticTexts?.footer_contacts_title}</div>
                {contacts?.email && <a href={`mailto:${contacts?.email}`}>
                    <div className="info-item">
                        {contacts?.email}</div>
                </ a>}
                {contacts?.phoneNumber && <a href={`tel:${contacts?.phoneNumber}`}>
                    <div className="info-item">{contacts?.phoneNumber}</div>
                </ a>}
                {contacts?.address && <div className="info-item">{contacts?.address}</div>}
                <a href={"https://www.facebook.com/fortunecup.am"} target={'_blank'}>
                    <div className={'fc_icon'}>
                        <Facebook_Icon title=''/>
                    </div>
                </a>
            </div>
            <div className="col">
                {
                    !!contacts?.socials?.length && <>
                        <div className="col-title">{staticTexts?.footer_social_title}</div>
                        <div className="social">
                            {
                                contacts?.socials.map(item => {
                                    return <a href={item.url}
                                              target={'_blank'}
                                              key={item.id}>
                                        <img src={generateImageMediaUrl(item?.icon?.path)} alt=""/>
                                    </a>
                                })
                            }
                        </div>
                    </>
                }
                <Dropdown overlay={menuLanguage}
                          trigger={['click']}
                          overlayClassName={'languages-wrapper'}
                          placement="topRight"
                          size={'small'}>
                    <div className="dropdown-menu-item">
                        {selectedLanguage?.icon &&
                        <img src={selectedLanguage?.icon} alt=""/>}
                        <div>{selectedLanguage?.code}</div>
                        <CollapseLangIcon className={'arrow-icon'} title={''}/>
                    </div>
                </Dropdown>

                <div className={`apps-wrapper`}>
                    <a href="https://play.google.com/store/apps/details?id=am.nikita.fortunecup&hl=en&gl=AM"
                       className={'google-play'}
                       target={'_blank'}>
                        <img src={google_play} alt=""/>
                    </a>
                    <a href="https://apps.apple.com/us/app/fortune-cup/id982142045"
                       target={'_blank'}>
                        <img src={apple_store} alt=""/>
                    </a>
                </div>
            </div>
        </div>
        <div className="bottom-side">
            <div className="copyright">
                {staticTexts?.footer_section_copyright_text}
            </div>
            <div className='developed-by'>
                <span> {staticTexts?.footer_section_developed_by_text}</span>
                <a href={"https://4steps.am/"} target={'_blank'}>
                    <CompanyIcon title=''/>
                </a>
            </div>
        </div>
    </footer>

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'selectedLanguage',
    ])
};

const mapDispatchToProps = {
    GetContacts,
    ChangeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
